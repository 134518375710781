import classNames from "classnames";
import React from "react";

export interface IconProps {
  className?: string;
}

const IconSearchSolid = ({ className = "w-6 h-6" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={classNames([className])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66567 4.66567C6.2129 3.11844 8.31139 2.24922 10.4995 2.24922C12.6876 2.24922 14.7861 3.11844 16.3333 4.66567C17.8806 6.2129 18.7498 8.31139 18.7498 10.4995C18.7498 12.4362 18.0688 14.3027 16.8395 15.7789L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L15.7789 16.8395C14.3027 18.0688 12.4362 18.7498 10.4995 18.7498C8.31139 18.7498 6.2129 17.8806 4.66567 16.3333C3.11844 14.7861 2.24922 12.6876 2.24922 10.4995C2.24922 8.31139 3.11844 6.2129 4.66567 4.66567ZM10.4995 3.74922C8.70921 3.74922 6.99225 4.46041 5.72633 5.72633C4.46041 6.99225 3.74922 8.70921 3.74922 10.4995C3.74922 12.2898 4.46041 14.0067 5.72633 15.2727C6.99225 16.5386 8.70921 17.2498 10.4995 17.2498C12.2898 17.2498 14.0067 16.5386 15.2727 15.2727C16.5386 14.0067 17.2498 12.2898 17.2498 10.4995C17.2498 8.70921 16.5386 6.99225 15.2727 5.72633C14.0067 4.46041 12.2898 3.74922 10.4995 3.74922Z"
      />
      <circle cx="10.5" cy="10.5" r="5.5" />
    </svg>
  );
};

export default IconSearchSolid;
