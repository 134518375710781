import classNames from "classnames";
import React from "react";

export interface IconProps {
  className?: string;
}

const IconVIPSolid = ({ className = "w-6 h-6" }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={classNames([className])}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5C0.895431 5 0 5.89543 0 7V17C0 18.1046 0.89543 19 2 19H22C23.1046 19 24 18.1046 24 17V7C24 5.89543 23.1046 5 22 5H2ZM7.98448 8.53556C8.06202 8.35704 8.17127 8.22315 8.31225 8.13389C8.45323 8.04463 8.6083 8 8.77747 8C9.03123 8 9.26032 8.08554 9.46474 8.25662C9.66915 8.42771 9.77136 8.64342 9.77136 8.90377C9.77136 9.03022 9.73964 9.16039 9.6762 9.29428L6.88486 15.3752C6.79322 15.576 6.65577 15.7322 6.4725 15.8438C6.28923 15.9479 6.09186 16 5.88039 16C5.66893 16 5.47156 15.9479 5.28829 15.8438C5.10502 15.7322 4.96757 15.576 4.87593 15.3752L2.08459 9.29428C2.0282 9.17527 2 9.0451 2 8.90377C2 8.65086 2.10573 8.43887 2.3172 8.26778C2.52866 8.08926 2.7648 8 3.02561 8C3.19478 8 3.34986 8.04463 3.49083 8.13389C3.63886 8.22315 3.75164 8.35704 3.82918 8.53556L5.91212 13.2887L7.98448 8.53556ZM12.4528 15.9888C12.1567 15.9888 11.9206 15.8996 11.7443 15.7211C11.5752 15.5351 11.4906 15.2859 11.4906 14.9735V9.00418C11.4906 8.69921 11.5752 8.45746 11.7443 8.27894C11.9206 8.09298 12.1567 8 12.4528 8C12.7559 8 12.9955 8.09298 13.1717 8.27894C13.348 8.45746 13.4361 8.69921 13.4361 9.00418V14.9735C13.4361 15.2859 13.348 15.5351 13.1717 15.7211C12.9955 15.8996 12.7559 15.9888 12.4528 15.9888ZM16.0367 15.7211C16.2129 15.8996 16.449 15.9888 16.7451 15.9888C17.0482 15.9888 17.2878 15.8996 17.4641 15.7211C17.6403 15.5351 17.7284 15.2859 17.7284 14.9735V13.0767H19.3884C20.2061 13.0767 20.844 12.8536 21.3022 12.4073C21.7674 11.9609 22 11.3473 22 10.5662C22 9.77778 21.7709 9.16411 21.3127 8.72524C20.8546 8.27894 20.2131 8.05579 19.3884 8.05579H16.6816C16.3926 8.05579 16.1706 8.13761 16.0155 8.30126C15.8604 8.4649 15.7829 8.69921 15.7829 9.00418V14.9735C15.7829 15.2859 15.8675 15.5351 16.0367 15.7211ZM19.9171 11.3026C19.7479 11.4589 19.4836 11.537 19.1241 11.537H17.7284V9.60669H19.1241C19.829 9.60669 20.1814 9.93026 20.1814 10.5774C20.1814 10.9047 20.0933 11.1464 19.9171 11.3026Z"
      />
    </svg>
  );
};

export default IconVIPSolid;
