exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-am-i-first-tsx": () => import("./../../../src/pages/am-i-first.tsx" /* webpackChunkName: "component---src-pages-am-i-first-tsx" */),
  "component---src-pages-clubs-tsx": () => import("./../../../src/pages/clubs.tsx" /* webpackChunkName: "component---src-pages-clubs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-list-tsx": () => import("./../../../src/pages/list.tsx" /* webpackChunkName: "component---src-pages-list-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-club-tsx": () => import("./../../../src/templates/club.tsx" /* webpackChunkName: "component---src-templates-club-tsx" */)
}

